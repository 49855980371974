import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CarouselGallery } from "../components/gridGallery";
import { Loading } from "../components/loading";
import { sliderGalleryImages } from "../components/images";
import { Gallery } from "../components/gallery";
import { Popup } from "../components/popup";

const PopupList = [
  {
    id: "1",
    image: "assets/popup1.png",
    clickUrl:
      "https://www.notion.so/2024-0e85849aecd84bfe820e77b6b0754e37?pvs=4",
  },
  // { id: "2", image: "assets/popup2.png" },
];
export const Main = () => {
  const GalleryRef = useRef<HTMLElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const scrollToGallery = () => {
    if (GalleryRef.current) {
      GalleryRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const onScroll = () => {
      if (GalleryRef.current) {
        const GalleryPosition = GalleryRef.current.getBoundingClientRect().top;
        const isVisible = GalleryPosition > window.innerHeight;

        setShowScrollButton(isVisible);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Wrap>
      <MainImageWrap>
        {!isImageLoaded && <Loading />}
        <MainImage
          src={"assets/home/main.webp"}
          alt="main page body"
          onLoad={() => setIsImageLoaded(true)}
          style={{ display: isImageLoaded ? "block" : "none" }}
        />
        {showScrollButton && (
          <ScrollButton onClick={scrollToGallery}>
            <img
              src={"assets/scroll-button.png"}
              alt={"ScrollButton"}
              style={{ display: isImageLoaded ? "block" : "none" }}
            />
          </ScrollButton>
        )}
        <ButtonWrap>
          <img
            src={"assets/1-main-page-button.png"}
            alt="go explain video button"
            onClick={
              () => window.open("https://youtu.be/7vwC4659jSs", "_blank")
              // Alert.fire('준비중', '준비중입니다.', 'info')
            }
            style={{
              zIndex: 1,
              display: isImageLoaded ? "block" : "none",
            }}
          />
        </ButtonWrap>
      </MainImageWrap>
      {isImageLoaded ? (
        <GalleryWrap ref={GalleryRef}>
          {sliderGalleryImages ? <Gallery /> : null}
        </GalleryWrap>
      ) : null}
      <div style={{ display: "absolute", width: "100%" }}>
        {PopupList.map((popup, index) => (
          <Popup
            key={popup.id}
            id={popup.id}
            image={popup.image}
            clickUrl={popup.clickUrl}
            index={index}
          />
        ))}
      </div>
    </Wrap>
  );
};

export const PageTemplate = styled.section`
  position: relative;

  & > img {
    width: 100%;
  }
`;

const Wrap = styled(PageTemplate)`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const MainImageWrap = styled(PageTemplate)`
  display: flex;
  justify-content: center;
  max-width: 1920px;
`;

const MainImage = styled.img`
  max-width: 1600px;
`;

const GalleryWrap = styled(PageTemplate)`
  max-width: 1600px;
  display: flex;
  margin: 0 auto;
  border: 2px solid transparent;
  box-sizing: border-box;
  height: 800px;
  width: 100%;
  margin-top: 150px;
  margin-bottom: 150px;
`;

const ScrollButton = styled.button`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledCarouselGallery = styled(CarouselGallery)`
  max-width: 1600px;
  width: 100%;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }
`;

const ButtonWrap = styled(PageTemplate)`
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 569px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  & > img {
    width: 30vw;
    height: auto;
    max-width: 100%;
  }
`;
